import * as React from "react"
import Layout from '../components/common/layout'
import {Container, Grid, Box} from "theme-ui";
import {Helmet} from "react-helmet";




const Index = () => {
  return(
      <Layout>
        <Helmet>
          <title>Landings</title>
          <meta name="robots" content="noindex"/>
        </Helmet>
        <Container>
          <Grid  gap={2} columns={[2, '2fr 2fr']}>
            <Box>
            </Box>
            <Box>
            </Box>
          </Grid>
        </Container>
      </Layout>
  )
};

export default Index;



